.header {
	color: white;
	width: 100%;
	display: flex;
	flex-direction: row;
	margin: 50px 0;
}

.header-element {
	width: 50%;
}

.nav-bar ul {
	display: flex;
	justify-content: space-around;
}

.nav-bar li {
	list-style: none;
}
.nav-bar li:hover {
	text-decoration: underline;
	cursor: pointer;
}
