* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
#game {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#game-field {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr);
	width: 280px;
	aspect-ratio: 1;

	margin: 25px 0;
	border-radius: 5px;
	box-shadow: 1px 2px 3px 2px grey;
}

.cell {
	/* border: 1px solid red; */
	display: flex;
	justify-content: center;
	align-items: center;
}

.cell:nth-child(2),
.cell:nth-child(5),
.cell:nth-child(8) {
	border-left: 2px solid black;
	border-right: 2px solid black;
}
.cell:nth-child(4),
.cell:nth-child(5),
.cell:nth-child(6) {
	border-top: 2px solid black;
	border-bottom: 2px solid black;
}

.cell:hover {
	background-color: rgb(223, 223, 223);
}
